section#comments {
    .display {
      color: light-dark(var(--light-foreground-color), var(--dark-foreground-color));
    }
    .comment {
      display: grid;
      column-gap: 1rem;
      grid-template-areas:
        "avatar name"
        "avatar time"
        "avatar post"
        "...... interactions";
      grid-template-columns: min-content;
      justify-items: start;
      margin: 2em auto 2em -1em;
      padding: 1em;
  
      .avatar-link {
        grid-area: avatar;
        height: 4rem;
        position: relative;
        width: 4rem;
  
        .avatar {
          height: 100%;
          width: 100%;
          clip-path: circle(32px at center);
          filter: invert(0%) sepia(0%) saturate(2%) hue-rotate(186deg) brightness(97%) contrast(101%);      
        }
      }
  
      .author {
        align-items: center;
        display: flex;
        font-weight: bold;
        gap: 0.5em;
        grid-area: name;
  
        .instance {
          background-color: var(--faint);
          border-radius: 9999px;
          color: var(--neutral);
          font-size: smaller;
          font-weight: normal;
          padding: 0.25em 0.75em;
  
          &:hover {
            opacity: 0.8;
            text-decoration: none;
          }
        }
      }
  
      time {
        @extend small !optional;
  
        grid-area: time;
        line-height: 1.5rem;
      }
  
      main {
        grid-area: post;
  
        p:first-child {
          margin-top: 0.25em;
        }
  
        p:last-child {
          margin-bottom: 0;
        }
      }
  
      footer {
        @extend small !optional;
  
        grid-area: interactions;
        margin-top: 0.925rem;
  
        .faves {
          color: inherit;
  
          &:hover {
            opacity: 0.8;
            text-decoration: none;
          }
  
          &::before {
            color: var(--accent-color);
            content: "💙";
            margin-inline-end: 0.25em;
          }
        }
      }
  
      .emoji {
        display: inline;
        height: 1.25em;
        vertical-align: middle;
        width: 1.25em;
      }
  
      .invisible {
        display: none;
      }
  
      .ellipsis::after {
        content: "…";
      }
  
      &.verified {
       .avatar-link::after {
          background-color: var(--secondary-accent-color);
          border-radius: 50%;
          bottom: -0.25rem;
          color: var(--secondary-accent-contrast);
          content: "🚀";
          display: block;
          font-size: 1.25rem;
          font-weight: bold;
          height: 1.5rem;
          line-height: 1.5rem;
          position: absolute;
          right: -0.25rem;
          text-align: center;
          width: 1.5rem;
        }
  
        .instance {
          background-color: var(--secondary-accent-color);
          color: var(--secondary-accent-contrast);
  
          &::before {
            content: "🚀";
            font-weight: bold;
            margin-inline-end: 0.25em;
            margin-inline-start: -0.25em;
          }
        }
      }
    }
  }